.kanbanBoard {
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-left: 250px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.kanbanHeader {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.kanbanColumns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 900px;
  margin-left: 5%;
  margin-bottom: 30px;
}

.kanbanColumn {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.columnTitle {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.emptyMessage {
  text-align: center;
  color: #888;
}

.task {
  background-color: #e0e0e0;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
}

.task:hover {
  background-color: #d0d0d0;
}

.toggleButton {
  width: 200px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 50px;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  background-color: #f9f9f9;
  border: 1px solid rgb(171, 168, 168);
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}

.instructions {
  margin-left: 50px;
}

.taskForm {
  position: fixed;
  top: 40%;
  left: 25%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-height: 90vh;
  overflow-y: auto;
  animation: fadeIn 0.3s ease;
}

.taskForm input[type="text"],
.taskForm input[type="date"],
.taskForm textarea {
  width: 90%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

label {
  margin-bottom: 8px;
  font-size: 14px;
  color: #333;
}

select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  appearance: none;
  cursor: pointer;
}

.cancelButton {
  margin-left: 20px;
}

.backLink {
  margin-top: 10px;
  font-size: 16px;
  margin-left: 250px;
  cursor: pointer;
  text-decoration: none;
}

@media only screen and (max-width: 990px) {
  .kanbanBoard {
    margin-left: 140px;
  }
  .kanbanColumns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: 20px;
  }
}
@media (max-width: 768px) {
.kanbanBoard {
  width: 200px;
  overflow: hidden;
  scroll-behavior: smooth;
}
  .kanbanColumns {
display: grid;
gap: 20px;
grid-template-columns: repeat(1, 1fr);    margin-bottom: 20px;
margin-left: 0;
  }
  .kanbanColumn{
    width: 90%;
    margin-left: 0;
  }
  .instructions {
    margin-left: 0;
    width: 50%;
  }
  .toggleButton {
    margin-left: 0;
  }
}