.login{
    background-color: darkblue!important;
    color: #ffff;
}

footer{
    margin-top: 20px;
p{
    color: #ffff!important;
}
}