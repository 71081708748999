.taskCard {
  background-color: #fff !important;
  border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin: 5px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 250px;
}
    .taskTitle {
      font-weight: bold;
      font-size: 16px;
      padding: 10px;
      margin: 0;
      margin-left: 30%;
    }

    .moveContainer {
      display: flex;
      align-items: center;
    }
    .detailsTitle {
   font-weight: bold;
   font-size: 1.5rem; /* Makes the text larger */

    }
    
    .popup {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 400px;
      height: 300px;
      background-color: white;
      border: 1px solid #000;
      padding: 20px;
      z-index: 999;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    
    .popupContent {
      max-height: 80vh;
      overflow-y: auto;
      padding: 10px;
      font-size: 14px;
      word-wrap: break-word; /* Ensure text wraps instead of breaking out of container */
      word-break: break-word; /* Allow line breaks within words */
      color: #333;
    }
    
    .popupContent p {
      margin-bottom: 10px;
    }
    
    .popupContent h2 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    
    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 16px;
      cursor: pointer;
    }
    .moveContainer p {
      margin-right: 10px;
    }
    p {
      font-size: 14px;
      color: #666;
      margin: 0;
      padding: 10px;
    }
  
.deleteButton {
  width: fit-content;
display: flex;
margin-top: 10px;
  background-color: red;
  margin-left: 100px;
  color: #f9f9f9;
}

@media only screen and (max-width: 768px) {
  .taskCard{
height:  300px;
  }
  .popup {
  left: 55%;
  width: 300px;
  }
}